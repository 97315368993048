.kimchii-header {
  .kimchii-title {
    padding: 3rem 0;
    font-weight: 400;
    font-size: 42px;
    background-color: #000000;
  }
  .kimchii-gradient {
    height: 200px;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(49,1,22,1) 34%, rgba(82,1,37,1) 51%, rgba(124,13,61,1) 67%, rgba(164,104,131,1) 82%, rgba(232,219,224,1) 91%, rgba(255,255,255,1) 100%);
  }
}

.kimchii-footer {
  padding-bottom: 20rem;
  background: rgb(0,0,0);
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    max-width: 80%;
  }


  .footer-link {
    color: hotpink;
  }
  .kimchii-footer-gradient {
    height: 300px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(91,10,45,1) 49%, rgba(255,255,255,1) 100%);
  }
}

.kimchii-tag-heading {
  font-size: 19px;
}

.kimchii-tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;

  .kimchii-tag {
    font-size: 12px;
    padding: 7px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    border: 1px solid black;
  }
}

.image-row {
  margin-top: 4rem;
  margin-bottom: 8rem;
  display: flex;
  justify-content: space-between;
}

.image-container {
  flex: 1;
  max-width: 33.33%;
  padding: 5px;
  box-sizing: border-box;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.neon {
  color: #fff;
  text-shadow:
          0 0 4px #fff,
          0 0 5px #fff,
          0 0 10px #fff,
          0 0 21px #bc13fe,
          0 0 41px #bc13fe,
          0 0 45px #bc13fe,
          0 0 60px #bc13fe,
          0 0 75px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
}

@keyframes pulsate {

  100% {
    text-shadow: 0 0 2px #fff,
    0 0 5px #fff,
    0 0 10px #fff,
    0 0 20px #bc13fe,
    0 0 40px #bc13fe,
    0 0 45px #bc13fe,
    0 0 50px #bc13fe,
    0 0 75px #bc13fe;

  }

  0% {
    text-shadow: 0 0 2px #fff,
    0 0 4px #fff,
    0 0 6px #fff,
    0 0 10px #bc13fe,
    0 0 45px #bc13fe,
    0 0 55px #bc13fe,
    0 0 70px #bc13fe,
    0 0 80px #bc13fe;

  }
}